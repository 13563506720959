.txt-user-location {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.txt-name-address {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.btn-edit-address {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border: 0px;
  background-color: transparent;
}

.btn-edit-address:hover {
  font-weight: 700;
  transition: all 0.3s linear;
}

.txt-des-address {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.txt-ul {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-total-price {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.div-circle {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border-color: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
}

.div-inner-circle {
  background-color: #ee4d2d;
  width: 16px;
  height: 16px;

  border-radius: 16px;
}

.btn-choose-wallet {
  border: 1px solid var(--Boder-Divider, #ececec);
  padding: 24px;
  background-color: transparent;
  border-radius: 8px;
}

.txt-title-choose-button {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding-left: 8px;
}

.txt-bank-name {
  color: var(--text-icon-lv-1, #363636);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  text-align: start;
}

.txt-bank-code {
  color: var(--text-icon-lv-2, #626262);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.txt-total-wallet-money {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding-left: 8px;
}

.txt-number-product-right {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-number-right {
  color: var(--text-icon-lv-1, #363636);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
}

.txt-total-all-price {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
}

.btn-buy-product {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-product-name {
  overflow: hidden;
  color: var(--text-icon-lv-1, #363636);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
