.btn-modal-close {
  border-radius: 8px;
  border: 1px solid var(--Boder-Divider, #ececec);
  background: var(--Surface-Surface-Surface-1, #fff);
  width: 69px;
  height: 48px;
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.btn-submit {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  width: 80px;
  height: 48px;
}
