.btn-create-accumulation {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  color: var(--text-icon-fix, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 6px 12px 6px 12px;
  border: 0px;
}

.txt-invest-list {
  color: var(--text-icon-lv-1, #363636);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-invest-title {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-total-accumulation {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}

.txt-table-title {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
