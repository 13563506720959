.main {
  background-color: #ee4d2d;
}

.txt-title-auth {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.txt-title-right {
  color: var(--text-icon-lv-1, #363636);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.title-input {
  color: var(--text-icon-lv-1, #363636);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.title-input-sm {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn-login {
  border-radius: 8px;
  background: var(--surface-surface-surface-pr-1, #ee4d2d);
  border: 0px;
  color: white;
  width: 100%;
  height: 48px;
  margin-top: 40px;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.btn-login-sm {
  border-radius: 8px;
  background: white;
  border: 0px;
  color: #ee4d2d;
  width: 100%;
  height: 48px;
  margin-top: 40px;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.btn-register {
  background-color: white;
  border-radius: 8px;
  border: 0px;
  color: #ee4d2d;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.txt-forgot {
  color: var(--text-icon-lv-2, #626262);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.txt-forgot:hover {
  color: #ee4d2d;
  transition: all 0.3s linear;
}

.txt-forgot-sm {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: 0px;
  background-color: transparent;
  padding-top: 12px;
}

.input-otp {
  width: 46px;
  height: 40px;
}
