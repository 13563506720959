.container-home {
  background-color: #f5f5f5;
}

.container-newfeed-left {
  width: 75%;
}

.img-newfeed {
  height: 414px;
  width: 100%;
}

.container-body-product {
  padding-top: 16px;
}

@media screen and (max-width: 768px) {
  .img-newfeed {
    height: 210px;
    transition: all 0.3s linear;
  }
  .container-newfeed-left {
    width: 100%;
  }
  .container-body-product {
    background-color: white;
    margin-top: 16px;
    padding: 0px;
  }
}

.img-newfeed-slice {
  height: 204px;
  width: 372px;
}

.txt-title-left {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.btn-left-product {
  border: 0px;
  background-color: #ee4d2d;
  color: white;
  max-width: 240px;
  min-width: 240px;
  text-align: start;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s linear;
}

.btn-left-block-product {
  border: 0px;
  background-color: white;
  color: #626262;
  max-width: 240px;
  min-width: 240px;
  text-align: start;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s linear;
}

.txt-item-product {
  overflow: hidden;
  color: var(--text-icon-lv-1, #363636);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
}

.txt-price-compare {
  color: var(--text-icon-lv-3, #adadad);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: line-through;
  text-align: start;
}

.txt-price {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
}

.txt-sold {
  color: var(--text-icon-lv-2, #626262);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card-items {
  width: 200px;
  margin-left: 12px;
}

@media screen and (max-width: 576px) {
  .card-items {
    width: 46%;
    transition: all 0.3s linear;
    margin-left: 12px;
  }
}

.img-product {
  width: 100%;
  height: 200px;
}

@media screen and (max-width: 576px) {
  .img-product {
    transition: all 0.3s linear;
    height: 150px;
  }
}
