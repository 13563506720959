.container-navbar {
  background-color: #ee4d2d;
}

.txt-click-tab {
  font-size: 14px;
  color: white;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn-click-tab {
  background: transparent;
  border: 0px;
  padding: 6px;
}

.btn-click-tab:hover {
  background: var(--surface-surface-surface-pr-2, #f16d53);
  border-radius: 8px;
  padding: 6px;
  transition: all 0.4s ease-out;
}

.container-search {
  height: 48px;
  width: 80%;
  background-color: white;
  display: flex;
  border-radius: 8px;
}

.input-search {
  width: 96%;
  height: 48px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.txt-account {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
