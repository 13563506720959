.txt-content-modal {
  color: var(--text-icon-lv-2, #626262);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.btn-close-modal {
  border-radius: 8px;
  border: 1px solid var(--Boder-Divider, #ececec);
  background: var(--Surface-Surface-Surface-1, #fff);
  height: 40px;
  width: 49%;
}

.btn-close-modal:hover {
  border: 1px solid var(--Boder-Divider, #ee4d2d);
  background: var(--Surface-Surface-Surface-1, white);
  color: #ee4d2d;
  transition: all 0.4s ease-out;
}

.btn-submit-modal {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  height: 40px;
  width: 49%;
}

.btn-submit-modal:hover {
  border: 1px solid var(--Boder-Divider, #ee4d2d);
  background: var(--Surface-Surface-Surface-1, #fbd4cd);
  color: #ee4d2d;
  transition: all 0.4s ease-out;
}
