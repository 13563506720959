.txt-title {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.txt-content {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}

.txt-content:hover {
  color: #ee4d2d;
  transition: all 0.3s linear;
}

.item-footer {
  margin-top: 0px;
}

@media screen and (max-width: 576px) {
  .item-footer {
    margin-top: 8px;
    transition: all 0.3s linear;
  }
}
