.txt-title-product {
  color: var(--text-icon-lv-1, #363636);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

@media screen and (max-width: 768px) {
  .txt-title-product {
    margin-top: 16px;
  }
}

.txt-price-compare {
  color: var(--text-icon-lv-3, #adadad);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration-line: strikethrough;
  padding-top: 24px;
  padding-bottom: 8px;
}

.txt-product-price {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding-top: 8px;
}

.txt-title-description {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding-top: 32px;
  padding-bottom: 4px;
}

.txt-description {
  color: var(--text-icon-lv-2, #626262);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-bottom: 32px;
}

.txt-add-number {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.input-add-number {
  border-radius: 8px;
  border: 1px solid var(--boder-boder-2, #adadad);
  width: 84px;
  height: 40px;
  text-align: center;
}

.btn-buy-product {
  border-radius: 8px;
  background: var(--surface-surface-surface-pr-1, #ee4d2d);
  border: 0px;
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  width: 110px;
  height: 48px;
}

.btn-buy-product:hover {
  background: white;
  color: #ee4d2d;
  transition: all 0.4s linear;
  border: 1px solid var(--boder-boder-3, #ee4d2d);
}

.btn-add-to-cart {
  border-radius: 8px;
  border: 1px solid var(--boder-boder-3, #ee4d2d);
  background: var(--surface-surface-surface-pr-5, #fdedea);
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  width: 205px;
  height: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.btn-add-to-cart:hover {
  background-color: #ee4d2d;
  color: white;
  transition: all 0.4s linear;
}

.txt-title-all-product {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.item-container {
  width: 200px;
  /* height: 344px; */
}

.slick-slide {
  padding: 0 8px;
}

.slick-list {
  margin: 0 2em;
}

.txt-item-product {
  overflow: hidden;
  color: var(--text-icon-lv-1, #363636);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
}

.img-item {
  width: 100%;
  height: 180px;
}

@media screen and (max-width: 768px) {
  .img-item {
    height: 240px;
  }
}

.img-product-detail {
  height: 460px;
}

@media screen and (max-width: 570px) {
  .img-product-detail {
    height: 300px;
    transition: all 0.4s linear;
  }
}

.btn-share-product {
  border-radius: 8px;
  border: 1px solid var(--boder-boder-3, green);
  background: var(--surface-surface-surface-pr-5, white);
  color: var(--text-icon-pr-1, green);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  width: 205px;
  height: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.btn-share-product:hover {
  background: green;
  color: white;
  transition: all 0.4s linear;
}
