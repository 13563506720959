.btn-goback-home {
  border-radius: 8px;
  border: 1px solid var(--boder-boder-3, #ee4d2d);
  background: var(--surface-surface-surface-pr-5, #fdedea);
  color: #ee4d2d;
  width: 156px;
  height: 48px;
}

.btn-goback-home:hover {
  background-color: #ee4d2d;
  color: white;
  transition: all 0.3s linear;
}

.btn-infor-product {
  border-radius: 8px;
  background: var(--surface-surface-surface-pr-1, #ee4d2d);
  color: white;
  width: 156px;
  height: 48px;
  border: 0px;
}

.btn-infor-product:hover {
  background-color: white;
  color: #ee4d2d;
  transition: all 0.3s linear;
  border: 1px solid var(--boder-boder-3, #ee4d2d);
}
