.btn-add-address {
  border: 0px;
  background-color: #ee4d2d;
  width: 193px;
  height: 48px;
  border-radius: 8px;
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-default {
  border-radius: 8px;
  border: 1px solid var(--Boder-Boder-3, #ee4d2d);
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  color: var(--text-icon-fix, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 78px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
