.btn-add-bank {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  color: var(--text-icon-fix, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border: 0px;
  width: 112px;
  height: 32px;
}

.btn-add-bank:hover {
  background: white;
  border: 1px solid #ee4d2d;
  color: #ee4d2d;
  transition: all 0.3s linear;
}

.txt-title-name-bank {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.txt-data-bank {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
