.btn-back-wallet {
  border-radius: 8px;
  border: 1px solid var(--Boder-Divider, #ececec);
  background: var(--Surface-Surface-Surface-1, #fff);
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  padding: 12px 20px 12px 20px;
}

.btn-cancel-wallet {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  border: 0px;
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  padding: 12px 20px 12px 20px;
}
