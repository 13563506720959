.container-home {
  background-color: #f5f5f5;
}

.img-newfeed {
  height: 410px;
}

@media screen and (max-width: 768px) {
  .img-newfeed {
    height: 210px;
    transition: all 0.3s linear;
  }
}

.txt-title-left {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.btn-left-product {
  border: 0px;
  background-color: #ee4d2d;
  color: white;
  max-width: 240px;
  min-width: 240px;
  text-align: start;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s linear;
}

.btn-left-block-product {
  border: 0px;
  background-color: white;
  color: #626262;
  max-width: 240px;
  min-width: 240px;
  text-align: start;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s linear;
}

.txt-item-product {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: start;
  line-height: 20px; /* 142.857% */
}

.txt-price-compare {
  color: var(--text-icon-lv-3, #ee4d2d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: line-through;
  text-align: start;
}

.txt-price {
  color: var(--text-icon-lv-1, #ee4d2d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
}

.txt-sold {
  color: var(--text-icon-lv-2, #626262);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card-items {
  width: 200px;
  margin-left: 12px;
}

@media screen and (max-width: 576px) {
  .card-items {
    width: 46%;
    transition: all 0.3s linear;
    margin-left: 12px;
  }
}
