.container-profile {
  background-color: #f5f5f5;
}

.txt-name {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.btn-item-choose {
  border: 0px;
  background-color: transparent;
  width: 100%;
  text-align: start;
  margin-top: 6px;
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  border-radius: 8px;
  transition: all 0.3s linear;
}

.btn-item {
  border: 0px;
  background-color: #fdedea;
  width: 100%;
  text-align: start;
  margin-top: 6px;
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  border-radius: 8px;
  transition: all 0.3s linear;
}

.txt-title-right {
  color: var(--text-icon-lv-1, #363636);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.container-right {
  margin-left: 24px;
}

@media screen and (max-width: 768px) {
  .container-right {
    margin-left: 0px;
  }
}

.btn-edit {
  border: 1px solid #ececec;
  background-color: transparent;
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  display: flex;
  align-items: center;
  color: var(--text-icon-lv-2, #626262);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn-change-password {
  border-radius: 8px;
  border: 1px solid var(--boder-boder-3, #ee4d2d);
  background: var(--surface-surface-surface-pr-5, #fdedea);
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.txt-profile-right {
  color: var(--text-icon-lv-1, #363636);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.txt-profile-left {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.title-input {
  color: var(--text-icon-lv-1, #363636);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn-submit-change-password {
  background-color: #ee4d2d;
  padding: 0px;
  border: 0px;
  width: 100%;
  height: 40px;
  margin-top: 32px;
  border-radius: 8px;
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.btn-close-modal {
  border-radius: 8px;
  border: 1px solid var(--boder-divider, #ececec);
  background: white;
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 69px;
  height: 40px;
}

.btn-submit {
  border-radius: 8px;
  background: var(--surface-surface-surface-pr-1, #ee4d2d);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  width: 48%;
  height: 48px;
  border: 0px;
}

.btn-submit-modal {
  border-radius: 8px;
  background: var(--surface-surface-surface-pr-1, #ee4d2d);
  border: 0px;
  color: var(--text-icon-fix, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 0px 12px 0px 12px;
}

.input-email::placeholder {
  color: #000000;
}
