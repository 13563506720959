.txt-order-id {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.txt-order-time {
  color: var(--text-icon-lv-2, #626262);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.txt-product-price {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.txt-title-product-price {
  color: var(--text-icon-lv-1, #363636);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.txt-product-detail-price {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
