.btn-recharge {
  border-radius: 8px;
  border: 1px solid var(--Boder-Boder-3, #ee4d2d);
  background: var(--Surface-Surface-Surface-Pr-5, #fdedea);
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 77px;
  height: 32px;
}

.btn-recharge:hover {
  background: var(--Surface-Surface-Surface-Pr-5, #ee4d2d);
  color: white;
  transition: all 0.3s linear;
}

.btn-withdraw {
  border-radius: 8px;
  background: var(--Surface-Surface-Surface-Pr-1, #ee4d2d);
  color: var(--text-icon-fix, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border: 0px;
  width: 73px;
  height: 32px;
}

.btn-withdraw:hover {
  border: 1px solid var(--Boder-Boder-3, #ee4d2d);
  background: var(--Surface-Surface-Surface-Pr-5, #fdedea);
  color: var(--text-icon-pr-1, #ee4d2d);
  transition: all 0.3s linear;
}

.btn-transfer-voucher {
  border-radius: 8px;
  border: 1px solid var(--Boder-Boder-3, #5ebb25);
  background: var(--Surface-Surface-Surface-Pr-5, #f5fdea);
  color: var(--text-icon-pr-1, #5ebb25);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 140px;
  height: 32px;
}

.btn-transfer-voucher:hover {
  background: var(--Surface-Surface-Surface-Pr-5, #5ebb25);
  color: white;
  transition: all 0.3s linear;
}

.txt-title-wallet {
  color: var(--text-icon-lv-1, #363636);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.txt-amount-wallet {
  color: var(--text-icon-pr-1, #ee4d2d);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}
